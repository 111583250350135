import React, { useState, Fragment, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect, useDispatch } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';
// import moment from 'moment';

import SearchIcon from '@material-ui/icons/Search';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { clearLineupForm } from '../../reducers/LineupsSearchReducer';

import DateFnsUtils from '@date-io/date-fns';
// import moment from 'moment';

import TocIcon from '@material-ui/icons/Toc';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import moment from 'moment';

import {
  TextField,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Switch,
  withStyles,
  // Switch,
  // Typography,
  // withStyles,
} from '@material-ui/core';

import {
  getCodeState,
  getCodeSex,
  getCodeRace,
  getCodeMugshotTypes,
  getCodeEyeColor,
  getCodeHanded,
  getCodeEthnicity,
  getCodeHairLength,
  getCodeHairColor,
} from '../../reducers/CodeReducer';
import {
  getInformixCodeSex,
  getInformixCodeHairColor,
  getInformixCodeEyeColor,
  getInformixCodeEthnicity,
} from '../../reducers/InformixCodesReducer';
import {
  clearLineupPersonForm,
  handleLineupChange,
  setAutoSearch,
  setModalShow,
  setPhotoUpload,
} from '../../reducers/LineupPersonSearchReducer';
// import moment from 'moment';
// import { useHistory, useParams } from 'react-router';
import { notify } from 'reducers/NotifierReducer';
import { setCircularLoading } from 'reducers/UiReducer';
import { BlobProvider } from '@react-pdf/renderer';
import {
  clearLineupInfo,
  setLineupFormData,
  setLineupFormDetails,
} from 'reducers/LineupsSearchReducer';
import PdfView from './LineupResultView/PdfView';
import { useHistory, useParams } from 'react-router';
import { TabValueContext } from '.';
const useStyles = makeStyles((theme) => ({
  filterAction: {
    textAlign: 'right',
    position: 'absolute',
    right: -4,
    bottom: 10,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  filter: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '0 10px 10px 0px',
    },
  },
  filterAction2: {
    textAlign: 'right',

    position: 'absolute',

    display: 'flex',
    alignItems: 'center',
    right: 8,
    bottom: 10,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  datePicker: {
    width: '100%',

    '& input::-webkit-input-placeholder': {
      fontSize: 14,
      opacity: 0.6,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  input: {
    flex: 1,
  },
  buttonProgress: {
    color: '#B00927',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  select: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  selectInput: {
    fontSize: '16px',
  },
  iconButton: {
    padding: 10,
  },
  reset: {
    marginRight: 20,
  },
  searchBtnWrap: {
    display: 'inline-block',
    position: 'relative',
    paddingRight: '1em',
  },
  searchWrap: {
    padding: '5px',
    height: '240px',
  },
  input2: {
    width: '100%',
  },
}));
const AntSwitch = withStyles((theme) => ({
  root: {
    width: 45,
    height: 24,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(20px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: '#8ce8ff',
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 19,
    height: 19,
    marginTop: '1px',
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 1,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);
const SearchForm = (props) => {
  const classes = useStyles();
  const [filters, setFilters] = useState();

  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const { id } = useParams();

  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [isUpdate, setIsUpdate] = React.useState(true);
  const options = ['Option 1', 'Option 2'];
  const [tabValue, setTabValue] = useContext(TabValueContext);
  const [value, setValue] = React.useState(options[0]);
  const [inputValue, setInputValue] = React.useState('');
  const {
    handleDateChange,
    handleFilterChange,
    handleRangeFilterChange,
    pickerValues,
    filterValues,

    getCodeMugshotTypes,
    clearLineupPersonForm,

    wsClient,
    lineupPickerValues,
    lineupFilterValues,
    isLineup,
    lineupFormDetails,
    lineupFormData,
    getInformixCodeSex,
    lineupPhotos,
    deletedPhotos,
    setLineupFormDetails,
    clearLineupInfo,
    setModalShow,
    autoSearch,
    setPhotoUpload,

    getInformixCodeHairColor,
    getInformixCodeEyeColor,

    getInformixCodeEthnicity,
  } = props;
  const loaded = props?.persons?.loaded || false;
  const history = useHistory();
  const codes = {
    sex: props.informixSexCodes,

    ethnicity: props.informixEthnicityCodes,
    haircolor: props.informixHairColorCodes,
    eyecolor: props.informixEyeColorCodes,
  };
  useEffect(() => {
    if (autoSearch == true) {
      props.handleLineupSearch();

      setLoading(true);
      dispatch(setAutoSearch(false));
    }
  }, []);
  useEffect(() => {
    setFilters(props.tableconfig.display);
  }, [isLineup]);
  useEffect(() => {
    if (lineupFormData.ptsLinupID) {
      setTabValue(1);
      const obj = {
        AgencyID: lineupFormData.AgencyID,
        IncidentID: lineupFormData.IncidentID,
        Notes: lineupFormData.Notes,
        ptsLinupID: lineupFormData.ptsLinupID,
        NumberOfPhoto: lineupFormData.NumberOfPhoto,
      };

      setLineupFormDetails(obj);
    }
  }, [id, lineupFormData.ptsLinupID]);
  useEffect(() => {
    setFilters(props.tableconfig.display);
  }, [isLineup]);
  useEffect(() => {
    if (id) {
      setTabValue(1);
    }
  }, [id]);
  useEffect(() => {
    loaded && loading && setLoading(false);
  }, [loaded, loading]);

  React.useEffect(() => {
    if (wsClient.websocket) {
      getInformixCodeSex();
      getCodeMugshotTypes();
      getCodeState();
      getInformixCodeEthnicity();
      getInformixCodeHairColor();
      getInformixCodeEyeColor();
    }
  }, [wsClient]);

  const handleModalShow = () => {
    setModalShow(true);
  };

  const handleInputChange = (e) => {
    const newForm = { ...lineupFormDetails };
    if (e.target.name == 'NumberOfPhoto') {
      if (e.target.value < lineupPhotos?.length) {
        dispatch(notify(`Number of photo cant not be less then Selected photo `, 'error'));
        return;
      }
    }
    newForm[e.target.name] = e.target.value;

    setLineupFormDetails(newForm);
  };

  const submitForm = (e) => {
    e.preventDefault();

    props.handleLineupSearch();
    if (isLineup) {
      setLoading(true);
    }
  };

  const saveData = async (e, isCreate = false) => {
    setOpenConfirmDialog(false);

    const images = [];
    const orderUpdateImage = [];
    lineupPhotos.forEach((e) => {
      if (isCreate) {
        images.push({
          FullName: e.FullName,
          Born: e.Born,
          Sex: e.Sex,
          Race: e.Race,
          SSNNumber: e.SSNNumber,
          OLNNumber: e.OLNNumber,
          ptsImageID: e.ptsImageID,
          OrderValue: e.OrderValue,
          Height: e.Height,
          Weight: e.Weight,
          HairColor: e.HairColor,
          EyeColor: e.EyeColor,
        });
      } else {
        if (!e.ptsLinupImageID) {
          images.push({
            FullName: e.FullName,
            Born: e.Born,
            Sex: e.Sex,
            Race: e.Race,
            Height: e.Height,
            Weight: e.Weight,
            HairColor: e.HairColor,
            EyeColor: e.EyeColor,
            SSNNumber: e.SSNNumber,
            OLNNumber: e.OLNNumber,
            ptsImageID: e.ptsImageID,
            OrderValue: e.OrderValue,
          });
        } else {
          orderUpdateImage.push(e);
        }
      }
    });

    const data = { info: lineupFormDetails, images: images };

    if (isCreate) {
      delete data.info.ptsLinupID;
    }

    if (!wsClient) {
      return;
    }

    try {
      dispatch(setCircularLoading(true));
      const service = wsClient?.websocket.service('image-lineups');
      const service2 = wsClient?.websocket.service('image-lineups-picture');
      service.timeout = 200000;
      service2.timeout = 200000;
      if (data.info.ptsLinupID) {
        const result = await service.patch(data.info.ptsLinupID, data.info);
        if (orderUpdateImage.length > 0) {
          orderUpdateImage.forEach(async (e) => {
            const update = await service2.patch(e.ptsLinupImageID, e);
            // console.log('update', update);
          });
        }
        if (images.length > 0) {
          const newImaes = images.map((e) => {
            return { ...e, ptsLinupID: data.info.ptsLinupID };
          });
          const result2 = await service2.create(newImaes);
        }
        if (deletedPhotos.length > 0) {
          deletedPhotos.forEach(async (e) => {
            const deleteResult = await service2.remove(e.ptsLinupImageID);
          });
        }
      } else {
        const result = await service.create(data);
        const newData = await service.get(result.ptsLinupID);
        const photo = newData.Mugshots ? JSON.parse(newData.Mugshots) : '';
        newData.photo = photo;

        handleLineupChange(true);
        dispatch(setLineupFormData(newData));
        history.push(`/lineup/${newData.ptsLinupID}`);
      }
      // clearLineupInfo();
      // history.push('/');
      dispatch(notify('SuccessFully created'));
    } catch (err) {
      dispatch(notify('Saveing Failed. Please try again.', 'error'));
    } finally {
      dispatch(setCircularLoading(false));
    }
  };

  const getTextFilter = (filter, idx, filterValues) => (
    <Grid item xs={2}>
      <TextField
        type="search"
        size="small"
        variant="outlined"
        label={filter.label}
        key={idx}
        className={classes.filter}
        value={filterValues[filter.dbName] ? filterValues[filter.dbName].search : ''}
        onChange={(e) => handleFilterChange(e, filter)}
      />
    </Grid>
  );

  const getRangeFilter = (filter, idx, filterValues) => (
    <>
      <Grid item xs={2}>
        <TextField
          type="search"
          size="small"
          variant="outlined"
          label={'Min ' + filter.label}
          key={idx + '1'}
          className={classes.filter}
          value={filterValues[filter.dbName] ? filterValues[filter.dbName].from : ''}
          onChange={(e) => handleRangeFilterChange(e, 'from', filter)}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          type="search"
          size="small"
          variant="outlined"
          label={'Max ' + filter.label}
          key={idx + '2'}
          className={classes.filter}
          value={filterValues[filter.dbName] ? filterValues[filter.dbName].to : ''}
          onChange={(e) => handleRangeFilterChange(e, 'to', filter)}
        />
      </Grid>
    </>
  );
  const getDateFilter = (filter, idx, pickerValues) => {
    const value = pickerValues[filter.dbName]
      ? pickerValues[filter.dbName]
      : { from: null, to: null };
    const minToValue = value.from ? value.from : undefined;
    const maxFromValue = value.to ? value.to : new Date();
    return (
      <Fragment key={idx}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item xs={2}>
            <KeyboardDatePicker
              className={classes.datePicker}
              inputVariant="outlined"
              clearable
              size="small"
              format="MM/dd/yyyy"
              value={value.from}
              placeholder={`${filter.label} From`}
              onChange={(date) => handleDateChange(date, filter.dbName, 'from')}
              maxDate={maxFromValue}
            />
          </Grid>
          <Grid item xs={2}>
            <KeyboardDatePicker
              className={classes.datePicker}
              inputVariant="outlined"
              clearable
              size="small"
              value={value.to}
              format="MM/dd/yyyy"
              placeholder={`${filter.label} To`}
              onChange={(date) => handleDateChange(date, filter.dbName, 'to')}
              minDate={minToValue}
              maxDate={new Date()}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </Fragment>
    );
  };

  const getDateSingleFilter = (filter, idx, pickerValues) => {
    const value = pickerValues[filter.dbName]
      ? pickerValues[filter.dbName]
      : { from: null, to: null };
    return (
      <Grid item xs={2}>
        <KeyboardDatePicker
          style={{ marginTop: 0, paddingTop: 0 }}
          key={idx}
          variant="inline"
          inputVariant="outlined"
          size="small"
          className={classes.datePicker}
          value={value.from}
          placeholder={filter.label}
          onChange={(date) => handleDateChange(date, filter.dbName, 'single')}
          format="MM/DD/yyyy"
        />
      </Grid>
    );
  };

  const getSelectFilter = (filter, idx, filterValues) => (
    <Grid item xs={2}>
      <FormControl variant="outlined" size="small" className={classes.select} key={idx}>
        <InputLabel id={`filter-${filter.dbName}`}>{filter.label}</InputLabel>
        <Select
          label={filter.label}
          className={classes.selectInput}
          labelId={`filter-${filter.dbName}`}
          value={filterValues[filter.dbName] ? filterValues[filter.dbName].search : ''}
          onChange={(e) => handleFilterChange(e, filter)}>
          <MenuItem value=""> </MenuItem>
          {isLineup
            ? codes[filter.label.split(' ').join('').toLowerCase()]?.map((code, idx) => (
                <MenuItem value={code[Object.keys(code)[0]]} key={idx}>
                  {code[Object.keys(code)[1]]}
                </MenuItem>
              ))
            : [6, 9, 12].map((code, idx) => (
                <MenuItem value={code} key={idx}>
                  {code}
                </MenuItem>
              ))}
        </Select>
      </FormControl>
    </Grid>
  );

  const clearForm = () => {
    if (isLineup) {
      clearLineupPersonForm();
    } else {
      dispatch(clearLineupForm());
    }
  };
  const handleClose = () => {
    setOpenConfirmDialog(false);
  };
  const handleOpen = () => {
    setOpenConfirmDialog(true);
  };
  const handleCreateNew = () => {
    saveData('', true);
  };
  const [state, setState] = React.useState({
    checkedB: true,
    checkedC: true,
  });

  const handleChange2 = (event) => {
    dispatch(handleLineupChange(event.target.checked));
  };
  const OnBack = () => {
    history.goBack();
    clearLineupInfo();
    dispatch(handleLineupChange(0));
  };

  return (
    <div className={classes.searchWrap}>
      <Dialog
        open={openConfirmDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          Do you want to update the Lineup or create a new one{' '}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">What do you want?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateNew} color="primary">
            Create New
          </Button>
          <Button onClick={saveData} color="primary" autoFocus>
            Update
          </Button>
        </DialogActions>
      </Dialog>
      {isLineup && tabValue == 1 ? (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Grid container spacing={2} xs={12} style={{ margin: '0 10px 10px 0px' }}>
            {id && (
              <Grid item xs={4}>
                <TextField
                  type="search"
                  size="small"
                  variant="outlined"
                  label="Lineup ID"
                  name="LineupID"
                  value={lineupFormDetails.ptsLinupID || ''}
                  className={classes.input2}
                />
              </Grid>
            )}
            <Grid item xs={4}>
              <TextField
                type="search"
                size="small"
                variant="outlined"
                label="Agency ID"
                name="AgencyID"
                value={lineupFormDetails.AgencyID || ''}
                onChange={(e) => handleInputChange(e)}
                className={classes.input2}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                type="search"
                size="small"
                label="Incident ID"
                name="IncidentID"
                value={lineupFormDetails.IncidentID || ''}
                onChange={(e) => handleInputChange(e)}
                variant="outlined"
                className={classes.input2}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }} variant="outlined" size="small">
                <InputLabel id="demo-customized-select-label">Number of Photos</InputLabel>
                <Select
                  label="NumberOfPhoto"
                  inputProps={{
                    name: 'NumberOfPhoto',
                    id: 'outlined-type-native-simple',
                  }}
                  value={lineupFormDetails.NumberOfPhoto}
                  onChange={(e) => handleInputChange(e)}>
                  <MenuItem value={6}>Six</MenuItem>
                  <MenuItem value={9}>Nine</MenuItem>
                  <MenuItem value={12}>Twelve </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item spacing={2} xs={6}>
              <TextField
                autoComplete="hidden"
                size="small"
                id="outlined-multiline-static-address-notes"
                label="Notes"
                rows="3"
                fullWidth
                variant="outlined"
                name="Notes"
                value={lineupFormDetails.Notes || ''}
                onChange={(e) => handleInputChange(e)}
                inputProps={{ maxLength: 490 }}
                multiline
              />
            </Grid>
          </Grid>
          <div className={classes.filterAction2}>
            {id && tabValue == 1 && (
              <Button
                style={{
                  width: '120px',
                  marginRight: lineupPhotos?.length > 0 ? '10px' : '0px',
                }}
                variant="contained"
                color="primary"
                startIcon={<ArrowBackIcon size="large" />}
                onClick={OnBack}>
                Back
              </Button>
            )}

            {lineupPhotos?.length > 0 && (
              <Button
                style={{
                  marginRight: '10px',
                }}
                variant="contained"
                color="primary"
                startIcon={<TocIcon fontSize="large" />}
                onClick={handleModalShow}>
                Reorder
              </Button>
            )}
            <Button
              style={{
                width: '120px',
                marginRight: lineupPhotos?.length > 0 ? '10px' : '0px',
              }}
              variant="contained"
              color="primary"
              startIcon={<CloudUploadIcon size="large" />}
              onClick={() => setPhotoUpload(true)}>
              Upload
            </Button>
            {lineupPhotos?.length > 0 && (
              <>
                {id && (
                  <BlobProvider
                    document={<PdfView imageData={lineupPhotos} formData={lineupFormDetails} />}
                    fileName="lineup.pdf">
                    {({ url }) => (
                      <a
                        href={url}
                        target="_blank"
                        style={{
                          width: '120px',
                          textAlign: 'center',
                          backgroundColor: '#0153a3',
                          color: 'whiteSmoke',
                          padding: '8px',
                          borderRadius: '5px',
                        }}>
                        Create Report
                      </a>
                    )}
                  </BlobProvider>
                )}

                <Button
                  style={{
                    width: '120px',
                    marginLeft: '10px',
                  }}
                  variant="contained"
                  color="primary"
                  onClick={id ? handleOpen : saveData}>
                  Save
                </Button>
              </>
            )}
          </div>
        </MuiPickersUtilsProvider>
      ) : (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div style={{ display: 'flex', padding: '8px 5px ' }}>
            <Typography component="div">
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>Create Lineup</Grid>
                <Grid item>
                  <AntSwitch checked={isLineup} onChange={handleChange2} name="checkedC" />
                </Grid>
              </Grid>
            </Typography>
          </div>
          <form onSubmit={submitForm}>
            <Grid container spacing={1} xs={12} style={{ margin: '0px 10px 10px 0px' }}>
              {filters &&
                Object.values(filters).map((filter, idx) => {
                  const filters = isLineup ? lineupFilterValues : filterValues;
                  const picker = isLineup ? lineupPickerValues : pickerValues;
                  switch (filter.type) {
                    case 'date':
                      return getDateFilter(filter, idx, picker);
                    case 'date-single':
                      return getDateSingleFilter(filter, idx, picker);
                    case 'select':
                      return getSelectFilter(filter, idx, filters);
                    case 'range':
                      return getRangeFilter(filter, idx, filters);
                    default:
                      return getTextFilter(filter, idx, filters);
                  }
                })}
            </Grid>
            <div className={classes.filterAction}>
              <Button
                startIcon={<DeleteOutlineIcon />}
                onClick={clearForm}
                className={classes.reset}
                disabled={loading}>
                Clear Form
              </Button>
              <div className={classes.searchBtnWrap}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: '120px' }}
                  className={classes.button}
                  startIcon={<SearchIcon />}
                  type="submit"
                  disabled={loading}>
                  Search
                </Button>
                {loading && (
                  <CircularProgress color="primary" size={24} className={classes.buttonProgress} />
                )}
              </div>
            </div>
          </form>
        </MuiPickersUtilsProvider>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  sexCodes: state.codes.sexCodes,
  raceCodes: state.codes.raceCodes,
  statesCodes: state.codes.statesCodes,
  pickerValues: state.LineupSearch.lineupForm.pickers,
  filterValues: state.LineupSearch.lineupForm.filters,

  hairLengthCodes: state.codes.hairLengthCodes,
  ethnicityCodes: state.codes.ethnicityCodes,
  handedCodes: state.codes.handedCodes,

  hairColorCodes: state.codes.hairColorCodes,
  eyeColorCodes: state.codes.eyeColorCodes,

  lineupPickerValues: state.lineupPersonSearch.personForm.pickers,
  lineupFilterValues: state.lineupPersonSearch.personForm.filters,
  isLineup: state.lineupPersonSearch.personForm.isLineup,
  autoSearch: state.lineupPersonSearch.personForm.autoSearch,
  recordType: state.lineupPersonSearch.personForm.recordType,
  lineupFormDetails: state.LineupSearch.lineupFormDetails,
  lineupPhotos: state.LineupSearch.lineupPhotos,
  deletedPhotos: state.LineupSearch.deletedPhotos,
  lineupFormData: state.LineupSearch.lineupFormData,
  modalShow: state.lineupPersonSearch.personForm.modalShow,
  photoUpload: state.lineupPersonSearch.personForm.photoUpload,
  informixSexCodes: state.InformixCodes.informixSexCodes,
  informixEyeColorCodes: state.InformixCodes.informixEyeColorCodes,
  informixHairColorCodes: state.InformixCodes.informixHairColorCodes,
  informixEthnicityCodes: state.InformixCodes.informixEthnicityCodes,
});

export default connect(mapStateToProps, {
  clearLineupForm,

  getCodeState,
  getCodeSex,
  getCodeRace,

  getCodeMugshotTypes,
  clearLineupPersonForm,

  setLineupFormDetails,
  clearLineupInfo,
  getInformixCodeSex,
  getInformixCodeEthnicity,
  getInformixCodeHairColor,
  getInformixCodeEyeColor,
  setModalShow,
  setPhotoUpload,
  getCodeEyeColor,
  getCodeHairColor,
  getCodeHanded,
  getCodeEthnicity,
  getCodeHairLength,
})(SearchForm);
