/* eslint-disable react/jsx-no-undef */
import { Card, Divider, TablePagination } from '@material-ui/core';
import moment from 'moment';
import {
  handleDateChange,
  handleFilterChange,
  handleRangeFilterChange,
} from '../../reducers/LineupsSearchReducer';
import {
  lineupHandleDateChange,
  lineupHandleFilterChange,
  lineupHandleRangeFilterChange,
} from '../../reducers/LineupPersonSearchReducer';
import React, { createContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { setLineupData } from 'reducers/LineupsSearchReducer';
import { notify } from 'reducers/NotifierReducer';
import lineupTableConfigPersons from '../../config/lineup-search-filters-config-persons.json';
import lineUpSearchConfig from '../../config/lineup-search-config.json';
import Search from './Search.js';
import Table from './Table.js';
import { findPersons } from 'reducers/LineupPersonSearchReducer';
import LineupResultView from './LineupResultView';
export const TabValueContext = createContext();
const LineupSearch = (props) => {
  const {
    websocket,
    filterValues,
    pickerValues,
    isLineup,
    recordType,
    lineupHandleDateChange,
    lineupHandleFilterChange,
    lineupHandleRangeFilterChange,

    handleDateChange,
    handleFilterChange,
    handleRangeFilterChange,
    setPersonPagination,
    personFilterValues,
    personPickersValue,
  } = props;
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const [firstLoad, setFirstload] = useState(true);
  const [pageNo, setPageNo] = useState(0);
  const [lineupPageNo, setLineupPageNo] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [lineupRowsPerPage, setLineupRowsPerPage] = useState(10);
  const { persons, personDetails } = props.personSearch;
  console.log('personpersonSearch', persons);
  console.log('persos', persons);
  useEffect(() => {
    if (!firstLoad) {
      handleLineupSearch('page');
    }
    setFirstload(false);
  }, [pageNo]);
  useEffect(() => {
    if (!firstLoad) {
      handleLineupSearch();
    }
    setFirstload(false);
  }, [rowsPerPage]);

  useEffect(() => {
    if (!firstLoad) {
      getPersonsData('page');
    }
    setFirstload(false);
  }, [lineupPageNo]);

  useEffect(() => {
    if (!firstLoad) {
      getPersonsData();
    }
    setFirstload(false);
  }, [lineupRowsPerPage]);

  const handleLineupSearch = async (flag = '', orderData = {}) => {
    if (!websocket) {
      return;
    }
    const filters = JSON.parse(JSON.stringify(filterValues));
    let newFilterValues = { filters: filters };

    Object.entries(pickerValues).forEach(([key, val]) => {
      newFilterValues.filters[key] = {
        type: 'date',
        from: moment(val.from).format('YYYY-MM-DD') + ' 00:00:00.0',
        to: moment(val.to).format('YYYY-MM-DD') + ' 23:59:59.0',
      };
    });

    try {
      setLoading(true);
      if (newFilterValues?.filters['l.NumberOfPhoto']?.search) {
        newFilterValues.filters['l.NumberOfPhoto'].search = String(
          newFilterValues.filters['l.NumberOfPhoto'].search
        );
      }
      newFilterValues = {
        ...newFilterValues,
        pageNo: flag == 'page' ? pageNo : 0,
        rowsPerPage,
        orderBy: orderData.orderBy || 'l.ptsLinupID',
        orderDirection: orderData.direction || 'desc',
      };

      const service = websocket?.websocket.service('image-lineups');
      service.timeout = 2000000;

      const result = await service.find({ query: newFilterValues });
      // const result = await service.find({ query: { ptsLinupID: 10 } });

      dispatch(setLineupData(result));
    } catch (err) {
      console.log(err);
      dispatch(notify('Loading Failed. Please try again.', 'error'));
    } finally {
      setLoading(false);
    }
  };
  const handlePageChange = async (pageNom) => {
    setPageNo(pageNom.page);
  };
  const handleChangeRowsPerPage = (params) => {
    setRowsPerPage(parseInt(params.pageSize));
  };
  const getPersonsData = (flag = '', passFilters = '', isOrderChange = false, orderValue = {}) => {
    const { orderBy, orderDirection } = persons;

    let newFilterValues = passFilters
      ? passFilters
      : JSON.parse(JSON.stringify(personFilterValues));

    if (newFilterValues['p.dob_dtd']) {
      if (newFilterValues['p.dob_dtd'].type == 'range') {
        newFilterValues['p.dob_dtd'].type = 'date';
        if (newFilterValues['p.dob_dtd'].from && newFilterValues['p.dob_dtd'].to) {
          [newFilterValues['p.dob_dtd'].from, newFilterValues['p.dob_dtd'].to] = [
            newFilterValues['p.dob_dtd'].to,
            newFilterValues['p.dob_dtd'].from,
          ];
        } else if (newFilterValues['p.dob_dtd'].from) {
          newFilterValues['p.dob_dtd'].to = newFilterValues['p.dob_dtd'].from;
          delete newFilterValues['p.dob_dtd'].from;
        } else if (newFilterValues['p.dob_dtd'].to) {
          newFilterValues['p.dob_dtd'].from = newFilterValues['p.dob_dtd'].to;
          delete newFilterValues['p.dob_dtd'].to;
        }
      }

      const time = new Date().getTime();
      if (newFilterValues['p.dob_dtd'].from) {
        const ageTime = newFilterValues['p.dob_dtd'].from * 31556926000;
        const dob = new Date(time - ageTime);
        newFilterValues['p.dob_dtd'].from = moment(dob).format('MM/DD/YYYY');
      }
      if (newFilterValues['p.dob_dtd'].to) {
        const ageTime = newFilterValues['p.dob_dtd'].to * 31556926000;
        const dob = new Date(time - ageTime);
        newFilterValues['p.dob_dtd'].to = moment(dob).format('MM/DD/YYYY');
      }
    }
    Object.entries(personPickersValue).forEach(([key, val]) => {
      newFilterValues[key] = {
        type: 'date',
        from: moment(val.from).format('MM/DD/YYYY'),
        to: moment(val.to).format('MM/DD/YYYY'),
      };
    });
    if (flag != 'page') {
      setLineupPageNo(0);
    }

    const options = {
      pageNo: flag == 'page' ? lineupPageNo : 0,
      orderBy: isOrderChange ? orderValue.orderBy : orderBy,
      rowsPerPage: lineupRowsPerPage,
      orderDirection: isOrderChange ? orderValue.direction : orderDirection,
      recordType: 'inmate',
      isLineup: true,
      filters: newFilterValues,
    };

    props.findPersons(options);
  };
  const handleLineupPageChange = async (ev, pageNom) => {
    setLineupPageNo(pageNom);
  };
  const handleLineupChangeRowsPerPage = (ev, info) => {
    setLineupRowsPerPage(parseInt(info.props.value));
  };
  return (
    <div>
      <TabValueContext.Provider value={[tabValue, setTabValue]}>
        <Card className="card-box m-3">
          {isLineup ? (
            <Search
              persons={persons}
              tableconfig={lineupTableConfigPersons}
              handleLineupSearch={getPersonsData}
              handleFilterChange={lineupHandleFilterChange}
              handleDateChange={lineupHandleDateChange}
              handleRangeFilterChange={lineupHandleRangeFilterChange}
            />
          ) : (
            <Search
              tableconfig={lineUpSearchConfig}
              handleFilterChange={handleFilterChange}
              handleDateChange={handleDateChange}
              handleRangeFilterChange={handleRangeFilterChange}
              handleLineupSearch={handleLineupSearch}
            />
          )}
        </Card>
        <Card className="card-box m-3">
          {isLineup ? (
            <LineupResultView persons={persons} />
          ) : (
            <Table
              loading={loading}
              handlePageChange={handlePageChange}
              rowsPerPage={rowsPerPage}
              handleLineupSearch={handleLineupSearch}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
          <Divider light />
          <div className=" d-flex justify-content-between">
            <div></div>
            <div>
              {tabValue !== 1 && isLineup && (
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50]}
                  component="div"
                  count={persons.count}
                  rowsPerPage={persons.rowsPerPage}
                  page={persons.pageNo}
                  onChangePage={handleLineupPageChange}
                  onChangeRowsPerPage={handleLineupChangeRowsPerPage}
                />
              )}
            </div>
          </div>
        </Card>
      </TabValueContext.Provider>
    </div>
  );
};

const mapStateToProps = (state) => ({
  websocket: state.websocket,
  filterValues: state.LineupSearch.lineupForm.filters,
  pickerValues: state.LineupSearch.lineupForm.pickers,
  personSearch: state.lineupPersonSearch,
  personFilterValues: state.lineupPersonSearch.personForm.filters,
  personPickersValue: state.lineupPersonSearch.personForm.pickers,

  isLineup: state.lineupPersonSearch.personForm.isLineup,
  recordType: state.personSearch.personForm.recordType,
});
export default connect(mapStateToProps, {
  findPersons,
  lineupHandleDateChange,
  lineupHandleFilterChange,
  lineupHandleRangeFilterChange,

  handleDateChange,
  handleFilterChange,
  handleRangeFilterChange,
})(LineupSearch);
