import {
  Button,
  Card,
  Modal,
  Grid,
  FormControl,
  InputLabel,
  Select,
  Paper,
  MenuItem,
  TextField,
} from '@material-ui/core';
import React, { useState } from 'react';
import ImageUploader from 'react-images-upload';
import {
  getInformixCodeSex,
  getInformixCodeHairColor,
  getInformixCodeEyeColor,
  getInformixCodeEthnicity,
} from '../../../reducers/InformixCodesReducer';

import { connect, useDispatch } from 'react-redux';
import defaultImage from '../../../assets/images/avatars/avatar-526x526.png';
import {
  setLineupFormData,
  setLineupPhotos,
  setDeletedPhotos,
} from 'reducers/LineupsSearchReducer';
import { setPhotoUpload, savePhoto } from '../../../reducers/PersonSearchReducer';
import { makeStyles } from '@material-ui/core/styles';

import { notify } from 'reducers/NotifierReducer';
const useStyles = makeStyles((theme) => ({
  filter: {
    width: '100%',
    textTransform: 'uppercase',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },

  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  input2: {
    width: '100%',
  },
  select: {
    width: '100%',
    textTransform: 'uppercase',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  selectInput: {
    fontSize: '16px',
    textAlign: 'left',
  },
}));

const UploadPhoto = (props) => {
  const classes = useStyles();
  const {
    getInformixCodeSex,
    getInformixCodeEthnicity,
    getInformixCodeHairColor,
    getInformixCodeEyeColor,
    setLineupPhotos,
    lineupPhotos,
    photoUpload,
    setPhotoUpload,
    savePhoto,
    websocket,
    informixSexCodes,
    informixEyeColorCodes,
    informixHairColorCodes,
    informixEthnicityCodes,
  } = props;

  const [mugshot, setMugshot] = useState([]);
  const [isPhotoUploaded, setIsPhotoUploaded] = useState(false);
  const [photo, setPhoto] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  React.useEffect(() => {
    if (websocket.websocket) {
      getInformixCodeSex();

      getInformixCodeEthnicity();
      getInformixCodeHairColor();
      getInformixCodeEyeColor();
    }
  }, [websocket]);
  function readFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function (e) {
        let dataURL = e.target.result;
        dataURL = dataURL.replace(';base64', `;name=${file.name};base64`);
        resolve({ file, dataURL });
      };

      reader.readAsDataURL(file);
    });
  }

  //   const handleSave = () => {
  //     if (!formData.values.ptsImageID) {
  //       upsertRecord('photo', formData.values, 'add').finally(() => handleFinally());
  //     } else {
  //       upsertRecord('photo', formData.values, 'edit').finally(() => handleFinally());
  //     }
  //   };

  //   const handleFinally = () => {
  //     dispatch(setCircularLoading(false));
  //     history.goBack();
  //   };

  const onDrop = (picture) => {
    readFile(picture[0]).then((fileData) => {
      setPhoto(fileData.dataURL);
    });
    setIsPhotoUploaded(true);
    setFormData({
      picture: picture[0],
    });
  };

  const handleClear = () => {
    setPhoto(false);
  };
  const handleClose = () => {
    setPhotoUpload(false);
  };
  const handleSave = () => {
    const data = savePhoto(formData);
    data
      .then((data) => {
        dispatch(notify('Uploaded Successfully'));
        const newObj = {
          ptsImageID: data.ptsImageID,
          Height: formData.Height,
          Weight: formData.Weight,
          FullName:
            (formData.LastName || '') +
            ' ' +
            (formData.FirstName || '') +
            ' ' +
            (formData.MiddleName || ''),
          EyeColor: formData.EyeColor || '',
          HairColor: formData.HairColor || '',
          uploadedImage: photo,
        };

        setLineupPhotos([...lineupPhotos, newObj]);
        setPhoto(false);
        setPhotoUpload(false);
      })
      .catch((er) => {
        console.log(er);
      });
  };

  const handleInputChange = (e) => {
    const newObject = { ...formData };
    newObject[e.target.name] = e.target.value;
    setFormData(newObject);
  };

  return (
    <Modal
      open={photoUpload}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      <Paper
        style={{
          width: '1000px',
          margin: 'auto',
          marginTop: '5px',

          padding: '10px',
          height: '98vh',
          textAlign: 'center',
        }}>
        <div>
          <ImageUploader
            withIcon={true}
            onChange={onDrop}
            imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
            maxFileSize={5242880}
            singleImage={true}
            buttonText={'Add Photo'}
          />
          <div
            style={{
              margin: 'auto',
              marginTop: '10px',
              marginBottom: '20px',
              width: '80%',
              textAlign: 'center',
            }}>
            {photo.length > 0 ? (
              <Card className="card-transparent mb-1 mb-sm-0">
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="card-img-wrapper rounded">
                  <img
                    alt="..."
                    className="card-img-top rounded"
                    src={photo}
                    style={{ width: '260px', maxHeight: '300px', objectFit: 'contain' }}
                  />
                </a>
              </Card>
            ) : (
              <Card className="card-transparent mb-3 mb-sm-0">
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="card-img-wrapper rounded">
                  <img
                    alt="..."
                    className="card-img-top rounded"
                    src={defaultImage}
                    style={{ width: '260px' }}
                  />
                </a>
              </Card>
            )}
          </div>
        </div>
        {photo.length > 0 ? (
          <Grid container spacing={2} xs={12} style={{ margin: '0 10px 10px 0px' }}>
            <Grid item xs={3}>
              <TextField
                type="search"
                size="small"
                variant="outlined"
                label="LastName"
                key={'LastName'}
                style={{ textTransform: 'uppercase' }}
                className={classes.filter}
                name="LastName"
                value={formData.LastName}
                onChange={(e) => handleInputChange(e)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                type="search"
                size="small"
                variant="outlined"
                label="FirstName"
                key={'FirstName'}
                style={{ textTransform: 'uppercase' }}
                className={classes.filter}
                name="FirstName"
                value={formData.FirstName}
                onChange={(e) => handleInputChange(e)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                type="search"
                size="small"
                variant="outlined"
                label="MiddleName"
                key={'MiddleName'}
                style={{ textTransform: 'uppercase' }}
                className={classes.filter}
                name="MiddleName"
                value={formData.MiddleName}
                onChange={(e) => handleInputChange(e)}
              />
            </Grid>

            <Grid item xs={3}>
              <FormControl
                variant="outlined"
                size="small"
                className={classes.select}
                key={'EyeColor'}>
                <InputLabel id={`EyeColor`}>Eye Color</InputLabel>
                <Select
                  label="EyeColor"
                  className={classes.selectInput}
                  labelId={`EyeColor`}
                  name="EyeColor"
                  value={formData.EyeColor}
                  onChange={(e) => handleInputChange(e)}>
                  <MenuItem value=""> </MenuItem>

                  {informixEyeColorCodes?.map((code, idx) => (
                    <MenuItem value={code.eyes_cd} key={idx}>
                      {code.eyes_desc}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl
                variant="outlined"
                size="small"
                className={classes.select}
                key={'HairColor'}>
                <InputLabel id={`HairColor`}>Hair Color</InputLabel>
                <Select
                  label="HairColor"
                  className={classes.selectInput}
                  labelId={`HairColor`}
                  name="HairColor"
                  value={formData.HairColor}
                  onChange={(e) => handleInputChange(e)}>
                  <MenuItem value=""> </MenuItem>

                  {informixHairColorCodes.map((code, idx) => (
                    <MenuItem value={code.hair_cd} key={idx}>
                      {code.hair_desc}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <TextField
                type="search"
                size="small"
                variant="outlined"
                label="Weight"
                key={'Weight'}
                style={{ textTransform: 'uppercase' }}
                className={classes.filter}
                name="Weight"
                value={formData.Weight}
                onChange={(e) => handleInputChange(e)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                type="search"
                size="small"
                variant="outlined"
                label="Height"
                key={'Height'}
                style={{ textTransform: 'uppercase' }}
                className={classes.filter}
                name="Height"
                value={formData.Height}
                onChange={(e) => handleInputChange(e)}
              />
            </Grid>
          </Grid>
        ) : null}
        <Button
          style={{ width: '100px', marginTop: '20px' }}
          onClick={handleSave}
          color="primary"
          aria-label="Settings"
          variant="contained"
          disabled={photo.length > 0 ? false : true}>
          Upload
        </Button>
        <Button
          style={{ width: '100px', marginTop: '20px', marginLeft: '10px' }}
          onClick={handleClear}
          color="primary"
          aria-label="Settings"
          variant="contained">
          Clear
        </Button>
      </Paper>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    websocket: state.websocket,
    lineupFormData: state.LineupSearch.lineupFormData,
    lineupFormDetails: state.LineupSearch.lineupFormDetails,
    lineupPhotos: state.LineupSearch.lineupPhotos,
    deletedPhotos: state.LineupSearch.deletedPhotos,
    photoUpload: state.personSearch.personForm.photoUpload,
    informixSexCodes: state.InformixCodes.informixSexCodes,
    informixEyeColorCodes: state.InformixCodes.informixEyeColorCodes,
    informixHairColorCodes: state.InformixCodes.informixHairColorCodes,
    informixEthnicityCodes: state.InformixCodes.informixEthnicityCodes,
  };
};

export default connect(mapStateToProps, {
  setLineupFormData,
  setPhotoUpload,
  setLineupPhotos,
  setDeletedPhotos,
  savePhoto,
  getInformixCodeSex,
  getInformixCodeEthnicity,
  getInformixCodeHairColor,
  getInformixCodeEyeColor,
})(UploadPhoto);
