import React, { Fragment, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { connect, useDispatch, useSelector } from 'react-redux';
import Webcam from 'react-webcam';
import {
  Button,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  TextField,
  Slide,
  DialogContentText,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { savePhoto, updatePhoto } from '../../reducers/PersonSearchReducer';
import { Grid } from '@material-ui/core';
import { setCircularLoading } from 'reducers/UiReducer';
import { notify } from 'reducers/NotifierReducer';

const useStyles = makeStyles((theme) => ({
  pageWrap: {
    display: 'flex',
    minHeight: '100%',
    padding: theme.spacing(2),
    '& > div': {
      heihgt: '100%',
      '&:first-child': {
        width: '55%',
      },
      '&:nth-child(2)': {
        width: '45%',
      },
    },
    '& h3': {
      fontSize: '1.25rem',
    },
  },
  eventList: {
    width: '30%',
  },
  eventsWrap: {
    display: 'flex',
  },
  btnGroup: {
    marginBottom: 10,
    marginLeft: 10,
    '&:first-child': {
      marginLeft: 0,
    },
  },
  eventDetails: {
    width: '70%',
    boxSizing: 'border-box',
    padding: '0 10px 0 10px',
  },

  units: {
    paddingLeft: '1em',
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const videoConstraints = {
  width: 1280,
  height: 720,
};
const MarkLocationCode = [{ Code: 'ML1' }, { Code: 'ML2' }];
const MarkTypeCode = [{ Code: 'MR1' }, { Code: 'MR2' }];
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function Camera(props) {
  const classes = useStyles();
  let history = useHistory();

  const { isOpen, close, open, mugshotTypes, imageDetail, user, wsClient } = props;

  const [deviceId, setDeviceId] = React.useState('');
  const [devices, setDevices] = React.useState([]);
  const [capturedImage, setCampturedImage] = React.useState(null);
  const { inmatesAdd, employeeAdd, inmatesViewOnly, employeesViewOnly } = useSelector(
    (state) => state.Permissions
  );
  const recordType = useSelector((state) => state.personSearch.personForm.recordType);

  // const [mugshotType, setMugshotType] = React.useState('');
  const [on, setOn] = React.useState(false);
  const [formData, setFormData] = useState();
  const dispatch = useDispatch();
  React.useEffect(() => {
    setFormData({
      ImageTypeCode: imageDetail?.ImageTypeCode || mugshotTypes[0].Code || '',

      IsMark: imageDetail?.IsMark || '',
      Location: imageDetail?.Location || '',
      MarkType: imageDetail?.MarkType || '',
      Gang: imageDetail?.Gang || '',
      Description: imageDetail?.Description || '',
    });
  }, [imageDetail]);
  const handleClose = () => {
    close();
  };
  const typeChange = (e) => {
    const newObj = { ...formData };
    if (e.target.name == 'IsDefault' && e.target.checked == true) {
      setOn(true);
    }
    newObj[e.target.name] = e.target.value || e.target.checked;
    setFormData(newObj);
  };
  const handleCameraChange = (e) => {
    setDeviceId(e.target.value);
  };

  const handleDevices = React.useCallback(
    (mediaDevices) => setDevices(mediaDevices.filter(({ kind }) => kind === 'videoinput')),
    [setDevices]
  );
  const handleInputChange = () => {};
  React.useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  React.useEffect(() => {
    if (devices.length > 0) {
      setDeviceId(devices[0].value);
    } else {
      setDeviceId('');
    }
  }, [devices]);

  const webcamRef = React.useRef(null);
  const url = useLocation();
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCampturedImage({ picture: imageSrc });
  }, [webcamRef, capturedImage]);

  const save = () => {
    let dataToSave = { ...capturedImage, ...formData };

    dataToSave.ptsPersonID = props.person;
    dataToSave.CreatedBy = user.userData ? user.userData.user.Username : null;
    dataToSave.picture = dataToSave.picture.substring(23);
    const userType = url.pathname.split('/')[2];

    const off = userType == 'inmate' ? 'inmate' : 'employee';
    props.savePhoto(dataToSave);
    setTimeout(function () {
      setFormData({});
      props.findPerson(props.person, off);
      close();
    }, 1500);
  };

  const handleUpdate = async () => {
    try {
      dispatch(setCircularLoading(true));
      const service = wsClient?.websocket.service('ic-person-photo');

      const data = await service.patch(imageDetail.ptsImageID, formData);

      const userType = url.pathname.split('/')[2];

      const off = userType == 'inmate' ? 'inmate' : 'employee';

      props.findPerson(props.person, off);
      dispatch(notify('Successfully Updated'));
    } catch (error) {
      dispatch(notify('Saving Failed. Please try again.', 'error'));
      console.log('error', error);
    } finally {
      dispatch(setCircularLoading(false));
      close();
    }
  };

  return (
    <Fragment>
      <Dialog
        fullWidth="true"
        maxWidth={imageDetail?.ptsImageID ? 'md' : 'lg'}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}>
        <Dialog
          open={on}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setOn(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description">
          <DialogTitle id="alert-dialog-slide-title" style={{ color: 'red' }}>
            {'Default Image Alert'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Selecting This Image As default Will Remove default flag from existing One.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOn(false)} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Photo capture
        </DialogTitle>
        <DialogContent dividers>
          <div style={{ display: 'flex' }}>
            {!imageDetail.ptsImageID && (
              <div style={{ height: '520px', width: '640px' }}>
                <Webcam
                  height={500}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  width={400}
                  videoConstraints={{ width: 400, height: 500, facingMode: 'environment' }}
                />
              </div>
            )}

            <div
              style={{
                marginLeft: '20px',
                width: `${imageDetail.ptsImageID ? '100%' : '600px'}`,
                height: '520px',
              }}>
              {(capturedImage !== null || imageDetail.ptsImageID) && (
                <Fragment>
                  {!imageDetail.ptsImageID && (
                    <div style={{ textAlign: 'center' }}>
                      <img
                        alt="..."
                        className="card-img-top rounded-sm"
                        src={capturedImage.picture}
                        style={{ width: 300 }}
                      />
                    </div>
                  )}

                  <Grid container xs={12} spacing={2} className="mt-2" justify="center">
                    <Grid item xs={6}>
                      <FormControl
                        style={{
                          width: `${imageDetail.ptsImageID ? '100%' : '280px'}`,
                          marginTop: '10px',
                        }}
                        size="small"
                        variant="outlined"
                        className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">
                          Mugshot Types
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label2"
                          id="demo-simple-select-outlined2"
                          value={formData.ImageTypeCode || ''}
                          name="ImageTypeCode"
                          onChange={(e) => typeChange(e)}
                          label="Mugshot Types">
                          <MenuItem value="">
                            <em>Mugshot Types</em>
                          </MenuItem>
                          {mugshotTypes.map((d, i) => {
                            return (
                              <MenuItem key={i} value={d.Code}>
                                {d.Code}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    {!imageDetail.ptsImageID && (
                      <Grid item xs={3} className="pt-3" justify="center">
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => typeChange(e)}
                              checked={formData.IsDefault || ''}
                              name="IsDefault"
                            />
                          }
                          label="Is Default?"
                        />
                      </Grid>
                    )}

                    <Grid item xs={imageDetail.ptsImageID ? 6 : 3} className="pt-3">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="IsMark"
                            onChange={(e) => typeChange(e)}
                            checked={formData.IsMark || ''}
                          />
                        }
                        label="Is Mark?"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        style={{
                          width: `${imageDetail.ptsImageID ? '100%' : '280px'}`,
                          marginTop: '10px',
                        }}
                        size="small"
                        variant="outlined"
                        className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">
                          Mark Location
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label2"
                          id="demo-simple-select-outlined2"
                          value={formData.Location || ''}
                          onChange={(e) => typeChange(e)}
                          name="Location"
                          label="Select Camera">
                          <MenuItem value="">
                            <em>Mark Location</em>
                          </MenuItem>
                          {MarkLocationCode?.map((d, i) => {
                            return (
                              <MenuItem key={i} value={d.Code}>
                                {d.Code}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        style={{
                          width: `${imageDetail.ptsImageID ? '100%' : '280px'}`,
                          marginTop: '10px',
                        }}
                        size="small"
                        variant="outlined"
                        className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Mark Type</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label2"
                          id="demo-simple-select-outlined2"
                          value={formData.MarkType || ''}
                          name="MarkType"
                          onChange={(e) => typeChange(e)}
                          label="Select Camera">
                          <MenuItem value="">
                            <em>Mark Type</em>
                          </MenuItem>
                          {MarkTypeCode?.map((d, i) => {
                            return (
                              <MenuItem key={i} value={d.Code}>
                                {d.Code}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        style={{ width: `${imageDetail.ptsImageID ? '100%' : '280px'}` }}
                        type="search"
                        size="small"
                        name="Gang"
                        label="Gang"
                        onChange={(e) => typeChange(e)}
                        variant="outlined"
                        value={formData.Gang || ''}
                        className={classes.filter}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        style={{ width: `${imageDetail.ptsImageID ? '100%' : '280px'}` }}
                        type="search"
                        size="small"
                        id="outlined-multiline-static-address-notes"
                        rows="3"
                        name="Description"
                        label="Description"
                        onChange={(e) => typeChange(e)}
                        variant="outlined"
                        value={formData.Description || ''}
                        className={classes.filter}
                        multiline
                      />
                    </Grid>
                  </Grid>
                </Fragment>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="d-flex justify-content-between" style={{ width: '100%' }}>
            {!imageDetail.ptsImageID && (
              <>
                <div>
                  <FormControl
                    style={{ width: `${imageDetail.ptsImageID ? '100%' : '280px'}` }}
                    size="small"
                    variant="outlined"
                    className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Select Camera</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={deviceId}
                      onChange={handleCameraChange}
                      label="Select Camera">
                      <MenuItem value="">
                        <em>No camera selected</em>
                      </MenuItem>
                      {devices.map((d, i) => {
                        return (
                          <MenuItem key={i} value={d.deviceId}>
                            {d.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <Button
                    style={{ marginLeft: '15px' }}
                    onClick={() => capture()}
                    autoFocus
                    variant="contained"
                    color="primary">
                    Capture photo
                  </Button>
                </div>
                <div>
                  {((recordType == 'inmate' && inmatesAdd && !inmatesViewOnly) ||
                    (recordType == 'employee' && !employeesViewOnly && employeeAdd)) && (
                    <Button
                      onClick={() => save()}
                      autoFocus
                      variant="contained"
                      color="primary"
                      className="mr-1">
                      Save photo
                    </Button>
                  )}
                </div>
              </>
            )}
            {imageDetail.ptsImageID && (
              <div>
                <Button
                  onClick={() => handleUpdate()}
                  autoFocus
                  variant="contained"
                  color="primary"
                  className="mr-1">
                  Update
                </Button>
              </div>
            )}
          </div>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    wsClient: state.websocket,
    persons: state.persons,
    config: state.config,
    user: state.user,
  };
};

export default connect(mapStateToProps, { savePhoto, updatePhoto })(Camera);
