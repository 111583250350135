import React, { Fragment, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Card, Divider } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';

import { makeStyles } from '@material-ui/core/styles';

import tableConfigPersons from '../../config/search-filters-config-persons.json';
import {
  findPersons,
  sortPersons,
  findPerson,
  setPersonPagination,
  handleDataSourceChange,
} from '../../reducers/PersonSearchReducer';

import Search from './search';
import { useParams } from 'react-router';
import moment from 'moment';
import UserTable from './table';

const useStyles = makeStyles((theme) => ({
  pageWrap: {
    display: 'flex',
    minHeight: '100%',
    padding: theme.spacing(2),
    '& > div': {
      heihgt: '100%',
      '&:first-child': {
        width: '55%',
      },
      '&:nth-child(2)': {
        width: '45%',
      },
    },
    '& h3': {
      fontSize: '1.25rem',
    },
  },
  eventList: {
    width: '30%',
  },
  eventsWrap: {
    display: 'flex',
  },
  btnGroup: {
    marginBottom: 10,
    marginLeft: 10,
    '&:first-child': {
      marginLeft: 0,
    },
  },
  eventDetails: {
    width: '70%',
    boxSizing: 'border-box',
    padding: '0 10px 0 10px',
  },
  units: {
    paddingLeft: '1em',
  },
}));

function Home(props) {
  const classes = useStyles();
  const { persons, personDetails } = props.personSearch;
  const {
    searchType,
    recordType,
    websocket,
    setPersonPagination,
    filterValues,
    pickersValue,
  } = props;
  const { id } = useParams();
  const [loading, setLoading] = React.useState(false);
  const { loaded } = persons;
  const [firstLoad, setFirstload] = useState(true);
  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [disableSourceChange, setDisableSourceChange] = useState(false);
  const dispatch = useDispatch();
  const { inmatesAccess, inmatesViewOnly, employeeAccess, employeeViewOnly } = useSelector(
    (state) => state.Permissions
  );

  useEffect(() => {
    if ((inmatesAccess || inmatesViewOnly) && (employeeAccess || employeeViewOnly)) {
      setDisableSourceChange(false);
    } else if (inmatesAccess || inmatesViewOnly) {
      dispatch(handleDataSourceChange('inmate'));
      setDisableSourceChange(true);
    } else if (employeeAccess || employeeViewOnly) {
      setDisableSourceChange(true);
      dispatch(handleDataSourceChange('employee'));
    } else {
      setDisableSourceChange(true);

      dispatch(handleDataSourceChange(''));
    }
  }, [inmatesAccess, inmatesViewOnly, employeeAccess, employeeViewOnly]);
  useEffect(() => {
    if (!firstLoad) {
      getPersonsData('page');
    }
    setFirstload(false);
  }, [pageNo]);

  useEffect(() => {
    if (!firstLoad) {
      getPersonsData();
    }
    setFirstload(false);
  }, [rowsPerPage]);

  useEffect(() => {
    loaded && loading && setLoading(false);
  }, [loaded, loading]);

  const getPersonsData = (flag = '', passFilters = '', isOrderChange = false, orderValue = {}) => {
    const { orderBy, orderDirection } = persons;

    let newFilterValues = passFilters ? passFilters : JSON.parse(JSON.stringify(filterValues));

    if (newFilterValues['p.dob_dtd']) {
      if (newFilterValues['p.dob_dtd'].type == 'range') {
        newFilterValues['p.dob_dtd'].type = 'date';
        if (newFilterValues['p.dob_dtd'].from && newFilterValues['p.dob_dtd'].to) {
          [newFilterValues['p.dob_dtd'].from, newFilterValues['p.dob_dtd'].to] = [
            newFilterValues['p.dob_dtd'].to,
            newFilterValues['p.dob_dtd'].from,
          ];
        } else if (newFilterValues['p.dob_dtd'].from) {
          newFilterValues['p.dob_dtd'].to = newFilterValues['p.dob_dtd'].from;
          delete newFilterValues['p.dob_dtd'].from;
        } else if (newFilterValues['p.dob_dtd'].to) {
          newFilterValues['p.dob_dtd'].from = newFilterValues['p.dob_dtd'].to;
          delete newFilterValues['p.dob_dtd'].to;
        }
      }

      const time = new Date().getTime();
      if (newFilterValues['p.dob_dtd'].from) {
        const ageTime = newFilterValues['p.dob_dtd'].from * 31556926000;
        const dob = new Date(time - ageTime);
        newFilterValues['p.dob_dtd'].from = moment(dob).format('MM/DD/YYYY');
      }
      if (newFilterValues['p.dob_dtd'].to) {
        const ageTime = newFilterValues['p.dob_dtd'].to * 31556926000;
        const dob = new Date(time - ageTime);
        newFilterValues['p.dob_dtd'].to = moment(dob).format('MM/DD/YYYY');
      }
    }
    Object.entries(pickersValue).forEach(([key, val]) => {
      newFilterValues[key] = {
        type: 'date',
        from: moment(val.from).format('MM/DD/YYYY'),
        to: moment(val.to).format('MM/DD/YYYY'),
      };
    });
    if (flag != 'page') {
      setPageNo(0);
    }

    const options = {
      pageNo: flag == 'page' ? pageNo : 0,
      orderBy: isOrderChange ? orderValue.orderBy : orderBy,
      rowsPerPage,
      orderDirection: isOrderChange ? orderValue.direction : orderDirection,
      recordType: recordType,
      filters: newFilterValues,
    };
    if (searchType == 'lineup' || passFilters) {
      options.searchType = 'lineup';
      options.recordType = 'inmate';
    }

    props.findPersons(options);
  };

  const handlePageChange = async (ev, pageNom) => {
    setPageNo(pageNom);
  };
  const handleChangeRowsPerPage = (ev, info) => {
    setRowsPerPage(parseInt(info.props.value));
  };
  console.log('comeoinfk');
  return (
    <Fragment>
      <Card className="card-box m-3">
        <Search
          getPersonsData={getPersonsData}
          persons={persons}
          tableconfig={tableConfigPersons}
          disapleSourceChange={disableSourceChange}
        />
      </Card>
      <Card className="card-box m-3">
        <div className="p1-3 px-3">
          <div className="card-header--title font-size-md">Persons</div>
          <div className="card-header--actions"></div>
        </div>
        <Divider light />
        <UserTable
          getPersonsData={getPersonsData}
          persons={persons}
          tableconfig={tableConfigPersons}
        />

        <Divider light />
        <div className=" d-flex justify-content-between">
          <div></div>
          <div>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={persons.count}
              rowsPerPage={persons.rowsPerPage}
              page={persons.pageNo}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </Card>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    websocket: state.websocket,
    personSearch: state.personSearch,
    config: state.config,
    filterValues: state.personSearch.personForm.filters,
    pickersValue: state.personSearch.personForm.pickers,

    searchType: state.personSearch.personForm.searchType,
    recordType: state.personSearch.personForm.recordType,
  };
};

export default connect(mapStateToProps, {
  findPersons,
  setPersonPagination,
  sortPersons,
  findPerson,
})(Home);
