import React, { useState, Fragment, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Button,
  Avatar,
  Box,
  Badge,
  Menu,
  List,
  ListItem,
  Divider,
} from '@material-ui/core';
import Tooltip from '../components/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  setNavigationDrawerMobileState,
  setEventsDrawerMobileState,
  setUnitsDrawerMobileState,
} from '../reducers/UiReducer';
import { setMode } from '../reducers/ThemeReducer';
import { setUserAuthenticated } from '../reducers/UserReducer';
import menuItems from '../config/configureMenu';
import settings from '../config/settings';
import { addPageDialog, openSettings, addPerson } from '../reducers/DialogsReducer';
import AboutDialog from './AboutDialog';
import Clock from './Clock';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  leftMenu: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    '& button': {
      '& span': {
        marginRight: 8,
      },
    },
  },
  rightMenu: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.topNav.bg,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: {
    height: settings.mainNavHeight,
    minHeight: settings.mainNavHeight,
    backgroundColor: theme.palette.bgElements,
  },
  btn: {
    width: '85px',
    height: '60px',
    border: '3px solid rgba(1, 83, 163, 0.5) !important',
  },
  btnLabel: {
    position: 'absolute',
    bottom: -3,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  activeNav: {
    '& > li': {
      fontWeight: 600,
    },
  },
  userName: {
    color: theme.colors.text1,
    marginLeft: 8,
  },
  arrowDn: {
    color: theme.colors.text1,
    marginLeft: 8,
  },
  fullWidthMenu: {
    zIndex: 2,
    position: 'absolute',
    top: settings.mainNavHeight,
    left: 0,
    right: 0,
    width: '100%',
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
  },
  searchMenu: {
    display: 'flex',
    '& h5': {
      paddingLeft: theme.spacing(2),
    },
    '& svg': {
      marginRight: theme.spacing(1),
      fontSize: 16,
      opacity: 0.25,
    },
    '& a': {
      color: theme.colors.text1,
    },
    '& a:hover': {
      color: theme.colors.text1,
    },
  },
  fwMenuWrap: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    zIndex: 2,
  },
}));

const UserBadge = withStyles({
  badge: {
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
})(Badge);

const MainNav = (props) => {
  const classes = useStyles();
  const { setMode, setUserAuthenticated, network, user, themeMode } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [displayAbout, setDisplayAbout] = useState(false);
  const [userName, setUserName] = useState('');
  const [searchMenuVisible, setSearchMenuVisible] = useState(false);
  const {
    inmatesAccess,
    inmatesCreateLineups,
    inmatesViewOnly,
    employeeViewOnly,
    employeeAccess,
    employeeCreateLineups,
    usersAdmin,
  } = useSelector((state) => state.Permissions);

  const history = useHistory();
  useEffect(() => {
    if (user.userData && user.userData.user) {
      let name = user.userData.user.Username;
      if (name !== userName) setUserName(name);
    }
    // eslint-disable-next-line
  }, [user]);

  const handleAboutClick = () => {
    setAnchorEl(null);
    setDisplayAbout(!displayAbout);
  };
  const handleCatagoriesClick = () => {
    setAnchorEl(null);
    history.push('/image-types');
  };
  const handleOpenSettings = () => {
    setAnchorEl(null);
    props.openSettings();
  };

  const StyledBadge = withStyles({
    badge: {
      backgroundColor: network.online === false ? 'var(--danger)' : 'var(--success)',
      color: network.online === false ? 'var(--danger)' : 'var(--success)',
    },
  })(UserBadge);

  const handleUserMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleColorMode = () => {
    setMode();
    handleClose();
  };

  const renderUserMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        transformOrigin={{ vertical: 'center', horizontal: 'center' }}
        onClose={handleClose}
        className="ml-2">
        <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
          <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
            <Box>
              <StyledBadge
                overlap="circle"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant="dot">
                <Avatar sizes="44" alt={userName} />
              </StyledBadge>
            </Box>
            <div>
              <div className="font-weight-bold text-center pt-2 line-height-1">{userName}</div>
            </div>
            <Divider className="w-100 mt-2" />
            <ListItem onClick={toggleColorMode} button>
              {themeMode === 'day' ? 'Night mode' : 'Day mode'}
            </ListItem>
            <ListItem onClick={handleAboutClick} button>
              About
            </ListItem>
            <ListItem onClick={handleCatagoriesClick} button>
              Image Types
            </ListItem>
            <ListItem onClick={handleOpenSettings} button>
              Settings
            </ListItem>
            <Divider className="w-100" />
            <ListItem className="d-block rounded-bottom px-3 pt-3 pb-0 text-center">
              <Button
                onClick={() => setUserAuthenticated(false)}
                variant="outlined"
                size="medium"
                color="default">
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={['fas', 'sign-out-alt']} />
                </span>
                <span className="btn-wrapper--label">Logout</span>
              </Button>
            </ListItem>
          </List>
        </div>
      </Menu>
    );
  };

  const renderDesktopMenu = () => {
    return (
      <div className={classes.wrapper}>
        <div className={classes.leftMenu}>
          <div>
            {menuItems.map((btn, idx) => {
              if (btn.label == 'LINEUPS') {
                if (!(inmatesCreateLineups && inmatesAccess && !inmatesViewOnly)) {
                  return;
                }
              }
              if (btn.label == 'ADMINISTRATION') {
                if (!usersAdmin) {
                  return;
                }
              }
              return (
                <Tooltip title={btn.tooltip} key={idx}>
                  <NavLink to={btn.link} activeClassName={classes.activeNav}>
                    <Button>{btn.label}</Button>
                  </NavLink>
                </Tooltip>
              );
            })}
          </div>
        </div>

        <div className={classes.rightMenu}>
          <div className="mr-3">
            <Clock />
          </div>
          <div>
            <Button
              color="inherit"
              onClick={handleUserMenuClick}
              className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
              <Box className={classes.avatar}>
                <Avatar sizes="30" alt={userName} />
              </Box>
              <span className={classes.userName}>{userName}</span>
              <span className={classes.arrowDn}>
                <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
              </span>
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <AppBar color="primary" position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          {renderDesktopMenu()}
          {renderUserMenu()}
        </Toolbar>
      </AppBar>
      {displayAbout && <AboutDialog close={handleAboutClick} />}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  network: state.offline,
  themeMode: state.theme.mode,
  user: state.user,
});

export default connect(mapStateToProps, {
  setNavigationDrawerMobileState,
  setEventsDrawerMobileState,
  setUnitsDrawerMobileState,
  setMode,
  setUserAuthenticated,
  addPageDialog,
  openSettings,
  addPerson,
})(MainNav);
