import { getService } from './service';
import { strToPath, getPathCenter } from '../utils/mapFunctions';
import { handleError } from './ErrorReducer';

const UPDATE_ZONES = 'UPDATE_ZONES';

/** Update redux zones */
export const updateZones = () => async (dispatch) => {
  try {
    const zones = await getZones();
    dispatch({ type: UPDATE_ZONES, zones });
  } catch (error) {
    dispatch(handleError(error, 'Error, cannot update zone information.'));
  }
};

export default function reducer(state = [], action) {
  switch (action.type) {
    case UPDATE_ZONES:
      return action.zones;
    default:
      break;
  }
  return state;
}

/** Return full zone info with geofences */
export const getZones = (AgencyID) => {
  return new Promise((resolve, reject) => {
    const service = getService();
    service
      .get({ type: 'zones', data: AgencyID })
      .then((result) => {
        resolve(
          result.map((zone) => {
            const path = strToPath(zone.Polygon);
            delete zone.Polygon;
            const center = path ? getPathCenter(path) : null;
            return { ...zone, path, center };
          })
        );
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/** Return only zone dictionary { ZoneCode, Description, AgencyID } */
export const getZoneNames = (AgencyID) => {
  const service = getService();
  return service.get({ type: 'zone-names', data: AgencyID });
};

// To be removed - old Zones
export const delZone = (ROWGUID) => {
  const service = getService();
  return service.patch(ROWGUID, { type: 'remove-zone' });
};

export const removeZone = (data) => {
  const service = getService();
  return service.patch(data.ROWGUID, { type: 'remove-zone', data });
};

export const importZones = (AgencyID, data) => {
  const service = getService();
  return service.patch(AgencyID, { type: 'import-zones', data });
};

export const saveZone = (data) => {
  const service = getService();
  if (data.ROWGUID) {
    return service.patch(data.ROWGUID, { type: 'update-zone', data });
  } else {
    return service.create({ type: 'create-zone', data });
  }
};

export const saveGeofence = (data) => {
  const service = getService();
  if (data.ptsGeofenceID) {
    return service.patch(data.ptsGeofenceID, { type: 'update-geofence', data });
  } else {
    return service.create({ type: 'create-geofence', data });
  }
};

export const addZoneToLocation = (ZoneCode, ptsEventLocationID) => {
  const service = getService();
  if (!ZoneCode || !ptsEventLocationID) return Promise.reject('Error, missing data');
  return service.create({ type: 'add-zone-to-location', data: { ZoneCode, ptsEventLocationID } });
};

export const delZoneFromLocation = (ZoneCode, ptsEventLocationID) => {
  const service = getService();
  return service.patch(ptsEventLocationID, {
    type: 'del-zone-from-location',
    data: {
      ZoneCode,
      ptsEventLocationID,
    },
  });
};

export const delGeofence = (ptsGeofenceID) => {
  const service = getService();
  return service.patch(ptsGeofenceID, { type: 'remove-geofence' });
};

export const getGeofences = (AgencyID) => {
  const service = getService();
  return new Promise((resolve, reject) => {
    service
      .get({ type: 'geofences', data: { AgencyID } })
      .then((result) => {
        resolve(
          result.map((zone) => {
            const path = strToPath(zone.Polygon);
            delete zone.Polygon;
            const center = path ? getPathCenter(path) : null;
            return { ...zone, path, center };
          })
        );
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getZoneMatch = (coords) => {
  const service = getService();
  return service.get({
    type: 'zone-match',
    data: coords,
  });
};
