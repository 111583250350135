import store from '../config/configureStore';

export const INMATES_ACCESS = 'PERMISSIONS/INMATES_ACCESS';
export const INMATES_VIEW_ONLY = 'PERMISSIONS/INMATES_VIEW_ONLY';
export const INMATES_ADD = 'PERMISSIONS/INMATES_ADD';
export const INMATES_EDIT = 'PERMISSIONS/INMATES_EDIT';
export const INMATES_TAKE_PHOTO = 'PERMISSIONS/INMATES_TAKE_PHOTO';
export const INMATES_DELETE = 'PERMISSIONS/INMATES_DELETE';
export const INMATES_CREATE_LINEUPS = 'PERMISSIONS/INMATES_CREATE_LINEUPS';
export const INMATES_VIEW_SSN = 'PERMISSIONS/INMATES_VIEW_SSN';
export const INMATES_REPORTS = 'PERMISSIONS/INMATES_REPORTS';
export const EMPLOYEE_ACCESS = 'PERMISSIONS/EMPLOYEE_ACCESS';
export const EMPLOYEE_VIEW_ONLY = 'PERMISSIONS/EMPLOYEE_VIEW_ONLY';
export const EMPLOYEE_ADD = 'PERMISSIONS/EMPLOYEE_ADD';
export const EMPLOYEE_EDIT = 'PERMISSIONS/EMPLOYEE_EDIT';
export const EMPLOYEE_DELETE = 'PERMISSIONS/EMPLOYEE_DELETE';
export const EMPLOYEE_TAKE_PHOTO = 'PERMISSIONS/EMPLOYEE_TAKE_PHOTO';
export const EMPLOYEE_CREATE_LINEUPS = 'PERMISSIONS/EMPLOYEE_CREATE_LINEUPS';
export const EMPLOYEE_VIEW_SSN = 'PERMISSIONS/EMPLOYEE_VIEW_SSN';
export const EMPLOYEE_REPORTS = 'PERMISSIONS/EMPLOYEE_REPORTS';
export const USERS_ADMIN = 'PERMISSIONS/USERS_ADMIN';
export const setPermissions = () => {
  const p = store.store.getState().user?.userData?.user?.Permissions;
  // console.log('permissions ds', p);
  const permissions = p && JSON.parse(p);
  console.log('permissions ds', permissions);

  return async (dispatch) => {
    permissions?.forEach((permission) => {
      if (permission.Code == 'INMATES_ACCESS') {
        dispatch({ type: INMATES_ACCESS, payload: true });
      } else if (permission.Code == 'INMATES_VIEW_ONLY') {
        dispatch({ type: INMATES_VIEW_ONLY, payload: true });
      } else if (permission.Code == 'INMATES_ADD') {
        dispatch({ type: INMATES_ADD, payload: true });
      } else if (permission.Code == 'INMATES_EDIT') {
        dispatch({ type: INMATES_EDIT, payload: true });
      } else if (permission.Code == 'INMATES_DELETE') {
        dispatch({ type: INMATES_DELETE, payload: true });
      } else if (permission.Code == 'INMATES_TAKE_PHOTO') {
        dispatch({ type: INMATES_TAKE_PHOTO, payload: true });
      } else if (permission.Code == 'INMATES_CREATE_LINEUPS') {
        dispatch({ type: INMATES_CREATE_LINEUPS, payload: true });
      } else if (permission.Code == 'INMATES_VIEW_SSN') {
        dispatch({ type: INMATES_VIEW_SSN, payload: true });
      } else if (permission.Code == 'INMATES_REPORTS') {
        dispatch({ type: INMATES_REPORTS, payload: true });
      } else if (permission.Code == 'EMPLOYEE_ACCESS') {
        dispatch({ type: EMPLOYEE_ACCESS, payload: true });
      } else if (permission.Code == 'EMPLOYEE_VIEW_ONLY') {
        dispatch({ type: EMPLOYEE_VIEW_ONLY, payload: true });
      } else if (permission.Code == 'EMPLOYEE_ADD') {
        dispatch({ type: EMPLOYEE_ADD, payload: true });
      } else if (permission.Code == 'EMPLOYEE_EDIT') {
        dispatch({ type: EMPLOYEE_EDIT, payload: true });
      } else if (permission.Code == 'EMPLOYEE_DELETE') {
        dispatch({ type: EMPLOYEE_DELETE, payload: true });
      } else if (permission.Code == 'EMPLOYEE_TAKE_PHOTO') {
        dispatch({ type: EMPLOYEE_TAKE_PHOTO, payload: true });
      } else if (permission.Code == 'EMPLOYEE_CREATE_LINEUPS') {
        dispatch({ type: EMPLOYEE_CREATE_LINEUPS, payload: true });
      } else if (permission.Code == 'EMPLOYEE_VIEW_SSN') {
        dispatch({ type: EMPLOYEE_VIEW_SSN, payload: true });
      } else if (permission.Code == 'EMPLOYEE_REPORTS') {
        dispatch({ type: EMPLOYEE_REPORTS, payload: true });
      } else if (permission.Code == 'USERS_ADMIN') {
        dispatch({ type: USERS_ADMIN, payload: true });
      }
    });
  };
};
export default function reducer(
  state = {
    // Person
    inmatesAccess: false,
    inmatesViewOnly: false,
    inmatesAdd: false,
    inmatesEdit: false,
    inmatesDelete: false,
    inmatesTakePhoto: false,
    inmatesCreateLineups: false,
    inmatesViewSsn: false,
    inmatesReports: false,
    employeeAccess: false,
    employeeViewOnly: false,
    employeeAdd: false,
    employeeEdit: false,
    employeeDelete: false,
    employeeTakePhoto: false,
    employeeCreateLineups: false,
    employeeViewSsn: false,
    employeeReports: false,
    usersAdmin: false,
  },
  action
) {
  switch (action.type) {
    // Person
    case USERS_ADMIN:
      return {
        ...state,
        usersAdmin: action.payload,
      };
    case INMATES_ACCESS:
      return {
        ...state,
        inmatesAccess: action.payload,
      };
    case INMATES_VIEW_ONLY:
      return {
        ...state,
        inmatesViewOnly: action.payload,
      };
    case INMATES_ADD:
      return {
        ...state,
        inmatesAdd: action.payload,
      };
    case INMATES_EDIT:
      return {
        ...state,
        inmatesEdit: action.payload,
      };
    case INMATES_DELETE:
      return {
        ...state,
        inmatesDelete: action.payload,
      };
    case INMATES_TAKE_PHOTO:
      return {
        ...state,
        inmatesTakePhoto: action.payload,
      };
    case INMATES_CREATE_LINEUPS:
      return {
        ...state,
        inmatesCreateLineups: action.payload,
      };
    case INMATES_VIEW_SSN:
      return {
        ...state,
        inmatesViewSsn: action.payload,
      };
    case INMATES_REPORTS:
      return {
        ...state,
        inmatesReports: action.payload,
      };
    case EMPLOYEE_ACCESS:
      return {
        ...state,
        employeeAccess: action.payload,
      };
    case EMPLOYEE_VIEW_ONLY:
      return {
        ...state,
        employeeViewOnly: action.payload,
      };
    case EMPLOYEE_ADD:
      return {
        ...state,
        employeeAdd: action.payload,
      };
    case EMPLOYEE_EDIT:
      return {
        ...state,
        employeeEdit: action.payload,
      };
    case EMPLOYEE_DELETE:
      return {
        ...state,
        employeeDelete: action.payload,
      };
    case EMPLOYEE_TAKE_PHOTO:
      return {
        ...state,
        employeeTakePhoto: action.payload,
      };
    case EMPLOYEE_CREATE_LINEUPS:
      return {
        ...state,
        employeeCreateLineups: action.payload,
      };
    case EMPLOYEE_VIEW_SSN:
      return {
        ...state,
        employeeViewSsn: action.payload,
      };
    case EMPLOYEE_REPORTS:
      return {
        ...state,
        employeeReports: action.payload,
      };
    default:
      break;
  }
  return state;
}
